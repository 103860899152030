import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/helper/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MicroGoalsIntro from '../../components/MicroGoalsIntro/MicroGoalsIntro.js';
import '../../assets/styles/App.css';
import '../../assets/styles/styles.css';
import Logo from '../../components/Logo/Logo';
import Modal from '../../components/Modal/Modal';
import SignOutButton from '../../components/SignOutButton/SignOutButton';
import SignOutModal from '../../components/SignOutModal/SignOutModal';
import Card from '../../components/Card/Card';
import './Home.css';

const getRandomPastelColor = () => {
  const mix = [255, 255, 255];
  const baseRed = Math.floor(Math.random() * 256);
  const baseGreen = Math.floor(Math.random() * 256);
  const baseBlue = Math.floor(Math.random() * 256);
  const red = Math.floor((baseRed + mix[0]) / 2);
  const green = Math.floor((baseGreen + mix[1]) / 2);
  const blue = Math.floor((baseBlue + mix[2]) / 2);
  return `rgb(${red}, ${green}, ${blue})`;
};

const Home = () => {
  const [cards, setCards] = useState(() => {
    const savedCards = localStorage.getItem('cards');
    return savedCards ? JSON.parse(savedCards) : [];
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [showIntro, setShowIntro] = useState(() => {
    return localStorage.getItem('showIntro') !== 'false';
  });
  const [user, setUser] = useState(null);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [colors, setColors] = useState({});

  useEffect(() => {
    const fetchNotes = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setUser(session.user);
        const { data: notes, error } = await supabase
          .from('notes')
          .select('*')
          .eq('user_id', session.user.id);
        if (error) {
          console.error('Error fetching notes:', error.message);
        } else {
          const initialCards = notes.map(note => ({
            date: new Date(note.created_at).toLocaleDateString(),
            color: getRandomPastelColor(),
            text: note.content,
            completed: note.completed,
            id: note.id
          }));
          setCards(initialCards);
          const initialColors = {};
          notes.forEach(note => {
            initialColors[note.id] = getRandomPastelColor();
          });
          setColors(initialColors);
        }
      }
    };

    fetchNotes();
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    localStorage.setItem('cards', JSON.stringify(cards));
  }, [cards]);

  const handleAddCard = async () => {
    console.log('Adding new card');
    const newDate = new Date().toLocaleDateString();
    const newColor = getRandomPastelColor();
    const newCard = { date: newDate, color: newColor, text: '', completed: false };

    // Sync with Supabase
    if (user) {
      console.log('User is logged in, adding card to Supabase');
      const { data, error } = await supabase
        .from('notes')
        .insert([{ user_id: user.id, content: '', created_at: new Date().toISOString(), completed: false }])
        .select() // Ensure we get the newly inserted data
        .single();
      if (error) {
        console.error('Error adding note:', error.message);
      } else {
        console.log('Note added successfully:', data);
        if (data) {
          const cardWithId = { ...newCard, id: data.id };
          setCards(prevCards => [...prevCards, cardWithId]);
          setColors(prevColors => ({ ...prevColors, [data.id]: newColor }));
        } else {
          console.error('Data is null, cannot read id');
        }
      }
    } else {
      console.log('User is not logged in, adding card locally');
      setCards(prevCards => [...prevCards, newCard]);
      setColors(prevColors => ({ ...prevColors, [newCard.date]: newColor }));
    }
  };

  const handleRemoveCard = async (index) => {
    const cardToRemove = cards[index];
    setCards(prevCards => prevCards.filter((_, cardIndex) => cardIndex !== index));

    // Sync with Supabase
    if (user && cardToRemove.id) {
      const { error } = await supabase
        .from('notes')
        .delete()
        .eq('id', cardToRemove.id);
      if (error) {
        console.error('Error deleting note:', error.message);
      }
    }
  };

  const handleTextChange = async (text, index) => {
    const updatedCards = cards.map((card, cardIndex) =>
      cardIndex === index ? { ...card, text } : card
    );
    setCards(updatedCards);

    // Sync with Supabase
    const cardToUpdate = updatedCards[index];
    if (user && cardToUpdate.id) {
      const { error } = await supabase
        .from('notes')
        .update({ content: text })
        .eq('id', cardToUpdate.id);
      if (error) {
        console.error('Error updating note:', error.message);
      }
    }
  };

  const handleToggleComplete = async (index) => {
    const updatedCards = cards.map((card, cardIndex) =>
      cardIndex === index ? { ...card, completed: !card.completed } : card
    );
    setCards(updatedCards);

    // Sync with Supabase
    const cardToUpdate = updatedCards[index];
    if (user && cardToUpdate.id) {
      const { error } = await supabase
        .from('notes')
        .update({ completed: !cardToUpdate.completed })
        .eq('id', cardToUpdate.id);
      if (error) {
        console.error('Error updating note:', error.message);
      }
    }
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error.message);
    } else {
      localStorage.removeItem('cards'); // Clear cards from local storage
      setShowSignOutModal(true);
    }
  };

  const closeSignOutModal = () => {
    setShowSignOutModal(false);
    window.location.href = '/'; // Redirect to landing page after closing modal
  };

  const handleCloseIntro = (dismissForever) => {
    setShowIntro(false);
    if (dismissForever) {
      localStorage.setItem('showIntro', 'false');
    }
  };

  return (
    <div className="home-container">
      <div className="home-header">
        <Logo />
        <SignOutButton onSignOut={handleSignOut} />
      </div>
      {showIntro && (
        <Modal onClose={() => handleCloseIntro(false)} onDismissForever={() => handleCloseIntro(true)}>
          <MicroGoalsIntro />
        </Modal>
      )}
      <div className="card-container">
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px',
          maxWidth: '100%',
        }}
      ></div>
        {cards.map((card, index) => (
          <Card
            key={card.id || index} // Use index as fallback key
            date={card.date}
            color={card.color}
            text={card.text}
            index={index}
            completed={card.completed}
            onRemove={handleRemoveCard}
            onTextChange={handleTextChange}
            onToggleComplete={handleToggleComplete}
            width={width}
          />
          
        ))}
        <button
          id="buttonTitle"
          title="AddSticker"
          onClick={handleAddCard}
          className="add-card-button"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      {showSignOutModal && (
        <SignOutModal onClose={closeSignOutModal} />
      )}
    </div>
  );
};

export default Home;
