import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import './Card.css';

// Card component with responsive design
const Card = ({ date, color, text, index, onRemove, onTextChange, onToggleComplete, completed, width }) => {
  const cardStyles = {
    width: width > 768 ? '23%' : '100%', // Adjust for different screen widths
    margin: '10px',
    padding: '20px',
    flexWrap: 'wrap',
    gap: '20px',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    backgroundColor: color,
    color: 'black',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <div style={cardStyles}>
      <p style={{ position: 'absolute', top: '10px', right: '10px' }}>{date}</p>
      <div style={{ margin: '10px' }}></div>
      <textarea
        value={text}
        onChange={(e) => onTextChange(e.target.value, index)}
        style={{
          width: '100%',
          height: '100px',
          padding: '15px',
          borderRadius: '0',
          border: 'none',
          background: 'transparent',
          color: 'black',
          resize: 'none',
          outline: 'none',
          fontSize: '16px',
          fontFamily: 'Arial, sans-serif'
        }}
      />
      <div style={{ position: 'absolute', bottom: '15px', left: '10px' }}>
        <input
          type="checkbox"
          id={`completed-${index}`}
          checked={completed}
          onChange={() => onToggleComplete(index)}
        />
        <label htmlFor={`completed-${index}`}>{completed ? 'Completed' : 'Not Completed'}</label>
      </div>
      <button
        id="buttonTitle"
        title="RemoveSticker"
        onClick={() => onRemove(index)}
        style={{
          fontSize: '10px',
          padding: '10px',
          background: '#333333',
          color: 'white',
          border: '0',
          borderRadius: '50%',
          position: 'absolute',
          top: '10px',
          left: '10px'
        }}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
    </div>
  );
};

export default Card;
