// src/components/Modal.js
import React from 'react';
import './Modal.css';

const Modal = ({ children, onClose, onDismissForever }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {children}
        {onClose && (
          <button onClick={onClose} className="close-button mt-4">
            CLOSE
          </button>
        )}
        {onDismissForever && (
          <button onClick={onDismissForever} className="sign-up-button mt-4">
            DON'T SHOW AGAIN
          </button>
        )}
      </div>
    </div>
  );
};

export default Modal;
