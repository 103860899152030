// src/pages/Login.js
import React from 'react';
import { supabase } from '../../pages/lib/helper/supabaseClient';

const Login = () => {
  const login = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'github',
    });
    if (error) {
      console.error('Error during login:', error.message);
    }
  };

  return (
    <div>
      <h1>Login Page</h1>
      <button onClick={login}>Login with GitHub</button>
    </div>
  );
};

export default Login;