// src/pages/LandingPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import MicroGoalsIntro from '../../components/MicroGoalsIntro/MicroGoalsIntro';
import Modal from '../../components/Modal/Modal';
import Card from '../../components/Card/Card';
import Logo from '../../components/Logo/Logo';
import '../../assets/styles/App.css';
import '../../assets/styles/styles.css';

const getRandomPastelColor = () => {
  const mix = [255, 255, 255];
  const baseRed = Math.floor(Math.random() * 256);
  const baseGreen = Math.floor(Math.random() * 256);
  const baseBlue = Math.floor(Math.random() * 256);
  const red = Math.floor((baseRed + mix[0]) / 2);
  const green = Math.floor((baseGreen + mix[1]) / 2);
  const blue = Math.floor((baseBlue + mix[2]) / 2);
  return `rgb(${red}, ${green}, ${blue})`;
};

const LandingPage = () => {
  const [cards, setCards] = useState(() => {
    const savedCards = localStorage.getItem('cards');
    return savedCards ? JSON.parse(savedCards) : [];
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [showIntro, setShowIntro] = useState(() => {
    return localStorage.getItem('showIntro') !== 'false';
  });
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    localStorage.setItem('cards', JSON.stringify(cards));
  }, [cards]);

  const handleAddCard = () => {
    if (cards.length >= 3) {
      setShowSignUpModal(true);
      return;
    }
    const newDate = new Date().toLocaleDateString();
    const newColor = getRandomPastelColor();
    setCards(prevCards => [
      ...prevCards,
      { date: newDate, color: newColor, text: '', completed: false }
    ]);
  };

  const handleRemoveCard = index => {
    setCards(prevCards => prevCards.filter((_, cardIndex) => cardIndex !== index));
  };

  const handleTextChange = (text, index) => {
    setCards(prevCards =>
      prevCards.map((card, cardIndex) =>
        cardIndex === index ? { ...card, text: text } : card
      )
    );
  };

  const handleToggleComplete = index => {
    setCards(prevCards =>
      prevCards.map((card, cardIndex) =>
        cardIndex === index ? { ...card, completed: !card.completed } : card
      )
    );
  };

  const handleCloseIntro = (dismissForever) => {
    setShowIntro(false);
    if (dismissForever) {
      localStorage.setItem('showIntro', 'false');
    }
  };

  const handleSignUp = () => {
    navigate('/signup'); // Redirect to Sign Up page
  };

  return (
    <div style={{ padding: '20px', backgroundColor: '#FFF', minHeight: '100vh', position: 'relative' }}>
      <Logo />
      {showIntro && (
        <Modal onClose={() => handleCloseIntro(false)} onDismissForever={() => handleCloseIntro(true)}>
          <MicroGoalsIntro />
        </Modal>
      )}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '10px',
          maxWidth: '100%',
        }}
      >
        {cards.map((card, index) => (
          <Card
            key={index}
            date={card.date}
            color={card.color}
            text={card.text}
            index={index}
            completed={card.completed}
            onRemove={handleRemoveCard}
            onTextChange={handleTextChange}
            onToggleComplete={handleToggleComplete}
            width={width}
          />
        ))}
        <button
          id="buttonTitle"
          title="AddSticker"
          onClick={handleAddCard}
          style={{
            marginTop: '30px',
            fontSize: '24px',
            padding: '15px 15px',
            background: 'green',
            border: '0',
            borderRadius: '50%',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      {showSignUpModal && (
        <Modal onClose={() => setShowSignUpModal(false)}>
          <h2 className="text-2xl font-semibold">Sign Up</h2>
          <p>Sign-in to add more than 3 stickers!</p>
          <button
            onClick={handleSignUp}
            className="sign-up-button mt-4"
          >
            SIGN IN
          </button>
        </Modal>
      )}
    </div>
  );
};

export default LandingPage;
