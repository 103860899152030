// src/components/MicroGoalsIntro.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons';

const MicroGoalsIntro = () => {
  return (
    <div className="container mx-auto p-8 text-white">
      <h1 className="text-3xl font-bold mb-4 headline">
        Micro-goals are small, actionable steps that help you achieve your larger goals.
      </h1>
      <h3 className="mb-7">To use the microactions app:</h3>
      <ul className="mb-8 list-none">
        <li className="mb-2 flex items-center">
          <FontAwesomeIcon icon={faHandPointRight} className="mr-2" />
          Write down your main goal.
        </li>
        <li className="mb-2 flex items-center">
          <FontAwesomeIcon icon={faHandPointRight} className="mr-2" />
          Break it down into small, manageable tasks.
        </li>
        <li className="mb-2 flex items-center">
          <FontAwesomeIcon icon={faHandPointRight} className="mr-2" />
          Use sticky notes to list each micro-task.
        </li>
        <li className="mb-2 flex items-center">
          <FontAwesomeIcon icon={faHandPointRight} className="mr-2" />
          Tick complete to keep track of your progress.
        </li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4"></h2>
      <div id="sticky-notes" className="flex flex-wrap gap-4">
        <div className="p-4 bg-yellow-200 rounded shadow">
          <p></p>
        </div>
        <div className="p-4 bg-yellow-200 rounded shadow">
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default MicroGoalsIntro;
