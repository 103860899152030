// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './assets/styles/App.css';
import { supabase } from './pages/lib/helper/supabaseClient';
import Home from './pages/Home/Home';
import Login from './components/Login/Login';
import SignUp from './components/Sign-Up/SignUp';
import LandingPage from './pages/LandingPage/LandingPage';

const App = () => {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const getSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      if (session) {
        navigate('/home'); // Redirect to Home if session exists
      }
    };

    getSession();

    const { data: subscription } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        navigate('/home'); // Redirect to Home on login
      }
    });

    return () => {
      subscription?.unsubscribe?.();
    };
  }, [navigate]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/home" element={<Home />} />
      <Route path="/signup" element={<SignUp />} />
    </Routes>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;