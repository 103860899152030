
// src/pages/SignUp.js
import React from 'react';
import { supabase } from '../../pages/lib/helper/supabaseClient'
import '../../assets/styles/App.css';
import '../../assets/styles/styles.css';

const SignUp = () => {
  const signUp = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'github',
    });
    if (error) {
      console.error('Error during sign-up:', error.message);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
      <h1 >Sign Up Here!</h1>
      <button className="sign-up-button mt-4" onClick={signUp}>Sign In with GitHub</button>
    </div>
    </div>
  );
};

export default SignUp;
