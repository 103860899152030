import React from 'react';
import '../../assets/styles/App.css';
import '../../assets/styles/styles.css';

const SignOutModal = ({ onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Signed Out</h2>
        <p>You have been signed out successfully.</p>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default SignOutModal;
