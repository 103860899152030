// src/components/Logo.js
import React from 'react';
import logo from '../../assets/images/logo192.png'; // Ensure the correct path to your logo
import './Logo.css'; 

const Logo = () => {
  return (
    <div className="logo-container" >
      <img src={logo} alt="Microactions Logo" className="logo"/>
      <h2 className="logo-text">MicroActions</h2>
    </div>
  );
};

export default Logo;
