import React from 'react';

const SignOutButton = ({ onSignOut }) => {
  return (
    <button onClick={onSignOut} className="sign-out-button">
      Sign Out
    </button>
  );
};

export default SignOutButton;
